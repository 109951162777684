body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
.h-100 {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.position-relative {
  position: relative;
}

.m-0 {
  margin: 0 !important;
}

.w-14 {
  width: 14px !important;
}

.w-16 {
  width: 16px !important;
}

.gap-0 {
  gap: 0 !important;
}

.bg-accent-light {
  background: #E0EEF7 !important;
}

.mt-auto {
  margin-top: auto !important;
}

.ms-auto {
  margin-left: auto !important;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100% !important;
}

.text-white {
  color: #ffffff !important;
}

.logo-text {
  padding: 30px 24px 10px;
}

.bottom-items {
  padding: 4px 9px 20px;
  width: 100%;
}

.bottom-items .ant-space-item {
  width: 100% !important;
}

.side-menu-slider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.setting-menu .ant-menu-submenu-title {
  display: flex;
}

.custom-tab-style .ant-tabs-ink-bar {
  display: none;
}

.custom-tab-style .ant-tabs-tab {
  padding: 3px 12px !important;
  font-size: 13px !important;
  border-radius: 4px;
  margin: 0 5px 0 0 !important;
}

.custom-tab-style .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}

.ant-layout-sider-zero-width-trigger {
  border-radius: 100% !important;
  background: #6D6C68 !important;
  left: 8px
}

.ant-layout .ant-layout-sider-zero-width-trigger:hover::after {
  display: none;
}

.open-side-menu .ant-layout-sider-zero-width-trigger {
  left: unset;
  inset-inline-end: -20px;
  background: #ffffff !important;
  color: #001529 !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background: #E0EEF7 !important;
  color: #6D6C68 !important;
}

.editor-custom-view {
  padding: 0;
  width: 100%;
  border: 1px solid #92B5D7;
  border-radius: 4px;
  background: #ffffff
}

.editor-custom-view .ck-content {
  border-radius: 4px !important;
  padding-top: 4px !important;
}

.read-only .ck-content {
  border: none !important;
  background: transparent !important;
  padding: 0 !important;
  line-height: 21px;
}

.editor-custom-view .ck-content,
.editor-custom-view .ck-content:focus,
.editor-custom-view .ck-content.ck-focused {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.editor-custom-view .ck-toolbar {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 1px solid #92B5D7 !important;
  margin: 0 5px !important;
  padding: 0 !important;
}

.ant-modal-title {
  color: #000000 !important;
}

.editable-wrapper.ant-typography-edit-content {
  margin-left: 11px !important;
}

.editable-wrapper .ant-input {
  box-shadow: none;
  border-color: #92B5D7 !important;
  font-size: 18px;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
  background: #002a52 !important;
}

.form-styles label {
  color: #000000 !important;
  font-weight: 500;
}

.form-styles .ant-select-selector {
  border: 1px solid rgb(146, 181, 215) !important;
}

.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}

.border-less-select .ant-select-selector {
  border: none !important;
}



.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  transition: top 0.3s;
}

.visible {
  top: 0;
}

.hidden {
  top: -100px;
}

.ant-tabs-top>.ant-tabs-nav::before {
  display: none;
}

.ml-responsive-8 {
  margin-left: 8px;
}

.common-space {
  margin-left: 20px;
}

.mt-responsive-4 {
  margin-top: 0;
}

.generic-table .ant-table-thead th{
  background: #E0EEF7 !important;
}
.generic-table .ant-table-row.inprogress {
  background: #1677FF0D;
}
.generic-table .ant-table-row.pending {
  background: #EC90070D;
}
.generic-table .ant-table-row.close {
  background: #1AA30E0D;
}
.generic-table .ant-table-row.rejected {
  background: #EC90070D;
}
.generic-table .ant-table-thead th,  .generic-table .ant-table-tbody td{
  padding: 10px 15px !important
}

.mweb-view {
  display: none;
}
.new-lead-form-modal .ant-modal-content, .new-lead-form-modal .ant-input-wrapper, .new-lead-form-modal .ant-select-selector, .new-lead-form-modal .prefix-select-input .ant-input {
  height: 100% !important;
}
.new-lead-form-modal .ant-select-selection-item {
  height: 100% !important;
  align-items: center;
  display: flex;
  justify-content: center;
}
.lead-nationality-radio .ant-radio-button-wrapper::before{
  display: none;
}
.lead-nationality-radio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #F4F4F4 !important;
  border-color: #002A52 !important;
  color: #343433 !important;
}
.new-lead-form-modal .ant-modal-body {
  background: #fff;
  border-radius: 4px;
  overflow-y: auto;
  height: calc(100vh - 130px);
}
.search-list-section .ant-card-body {
  padding: 16px;
}

.side-menu-slider {
  transition: all .6s ease-in-out;
  width: 200px !important;
}
.side-menu-slider.close-side-menu {
  min-width: 0 !important;
  max-width: 0 !important;
}
.side-menu-slider.open-side-menu {
  min-width: 200px !important;
  max-width: 200px !important;
}

/* .side-menu-slider.close-side-menu .ant-layout-sider-children {
  margin-left: -100% !important;
}
.side-menu-slider.open-side-menu .ant-layout-sider-children {
  margin-left: 0 !important;
} */



@media screen and (max-width: 992px) {
  .side-menu-slider.open-side-menu::before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }

  .main-content-body .ant-breadcrumb {
    padding-left: 60px;
    padding-top: 20px;
  }

  .side-menu-slider .ant-layout-sider-zero-width-trigger {
    top: 24px !important;
  }

  .page-heading {
    padding-left: 38px;
  }

  .ant-card-body .editor-custom-view {
    width: 90%;
  }

  .main-page-row.fixed-header {
    padding-left: 0 !important;
  }

  .details-header.fixed-header {
    padding-left: 60px !important;
  }
  /* .details-header.fixed-header .main-task-headeing {
    padding-left: 0 !important;
  } */

  .ml-responsive-8 {
    margin-left: 0;
  }

  .common-space {
    margin-left: 0;
    margin-top: 20px;
  }

  .wrapper-class h5 {
    margin-top: 20px;
  }

  .wrapper-class .ant-form .ant-row {
    margin-bottom: 0 !important;
  }

  .wrapper-class .action-row {
    margin-top: 20px;
  }

  .wrapper-class .two-col-heading {
    margin-top: 0;
  }

  .mt-responsive-4 {
    margin-top: 20px !important;
  }

}

@media screen and (max-width: 767px) {
  .ant-calendar-input-wrap {
    display: none !important;
  }
  
  .hidden {
    top: -200px
  }

  .mweb-hidden-elem {
    display: none !important;
  }

  .mwm-0 {
    margin: 0 !important;
  }

  .mwm-0.mr-2 {
    margin-right: 0 !important;
  }

  .main-content-body {
    padding-top: 115px !important;
  }

  .main-page-row .filterd-header {
    width: 100%;
    justify-content: space-between !important;
    padding: 18px 15px 10px !important;
    margin: 0 !important;
  }

  .filterd-header .task-search-btn {
    padding: 0 !important;
  }

  .filterd-header .task-search-btn {
    width: 230px;
  }

  .add-new-btn {
    order: 2;
  }

  .task-search-btn {
    order: 1;
  }

  .filter-btn {
    order: 3;
  }

  .page-heading {
    padding-left: 28px;
    padding-top: 10px;
  }

  .wrapper-class .ant-input,
  .wrapper-class .ant-input-affix-wrapper {
    width: 100% !important;
  }
  .desktop-view {
    display: none;
  }
  .mweb-view {
    display: flex;
  }
  .search-form-item {
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      padding-right: 0 !important;
  }
  .search--parent {
      width: 100% !important;
      margin-top: 20px;
  }
  .search-list-section {
      width: 100% !important;
  }
  .m-web-slider .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .details-header.fixed-header {
    padding-left: 0 !important;
    padding-bottom: 25px !important;
  }
  .breadcrum--custom-css {
    margin-top: -60px;
  }
  .follow-up-details .ant-card-meta-title {
    margin-bottom: 2px !important;
  }
  .follow-up-details .ant-card-meta-detail {
    margin-bottom: 20px;
  }
  .main-content-body .ant-breadcrumb {
    padding-left: 10px;
    padding-top: 0 !important;
  }
}

@media(max-width: 789px) {
  .ant-picker-panels {
    flex-direction: column;
  }
  .ant-picker-dropdown {
    top: 20px!important;
  }
}

.map-container {
  height: 450px;
  width: 100%;
}