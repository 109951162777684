.timeline-wrapper .ant-timeline-item-head {
    background: #C6C6C6;
}
.timeline-wrapper .ant-timeline-item-tail {
    border-color: #D9D9D9;
}
.timeline-wrapper .ant-timeline-item-head.ant-timeline-item-head-red {
    background: #EC0707;
    color: #EC0707;
}
.timeline-modal .anticon-close {
    background: #002A52;
    color: #ffff;
    border-radius: 100%;
    padding: 4px;
    font-size: 13px;
}
.timeline-modal .ant-modal-header {
    border: 0;
    padding-bottom: 0;
}
.filter-dropdown {
    background: #E0EEF7;
    color: #000000;
    padding: 2px 10px;
    border-radius: 4px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
}
.filter-dropdown:focus, .filter-dropdown:hover {
    color: #000000;
}
.filter-dropdown.selected-dropdown {
    background: #002A52;
    color: #ffffff;
}
.filter-dropdown.selected-dropdown:focus, .filter-dropdown.selected-dropdown:hover {
    color: #ffffff;
}
.permission-tag {
    background: #E0EEF7;
    border: 1px solid #92B5D7;
    border-radius: 4px;
    text-align: center;
    padding: 6px 10px;
    color: #343433;
    font-weight: 500;
    margin-bottom: 5px;
    margin-right: 5px;
    cursor: pointer;
}
.permission-tag.selected-p-tag {
    background: #002A52;
    border: 1px solid #002A52;
}
.permission-tag span {
   cursor: pointer;
}
.permission-tag.selected-p-tag span {
    color: #fff !important;
}

.permisstion-slider-arrow {
    position: absolute;
    top: 0;
    margin: auto;
    bottom: 0;
    background: transparent;
    border: 0;
    padding: 0
}
.permisstion-slider-arrow.left {
    left: -16px;
}
.permisstion-slider-arrow.right {
    right: -22px;
}
.permission-carousel .ant-row {
    display: flex !important;
}
.section-wrapper {
    margin-top: -60px;
    padding: 0 15px
}
.emp-tab-view {
    width: 100%;
}
.emp-section-view {
    margin: 20px 0;
}

@media(max-width: 768px) {
    .section-wrapper {
        margin: -150px -10px 0;
        padding: 0
    }
    .main-content-body .breadcrum-section {
        padding: 45px 0 20px 60px !important;
        background: #01142a;
    }
    .main-content-body .breadcrum-section .ant-breadcrumb-link, .main-content-body .breadcrum-section .ant-breadcrumb-separator {
        color: #fff;
    }
    .emp-section-view {
        margin: -4px 0 0 !important;
    }
    .emp-tab-view .ant-tabs-nav{
        background: #01142a;
        padding: 16px 0 0;
    }
    .emp-tab-view .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
        background: #f5f5f5 !important;
    }
    .emp-tab-view .ant-tabs-content-holder {
        padding: 0 14px;
    }
    .emp-tab-view .ant-card {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    }
    .emp-tab-view .ant-tabs-tab {
        border: 0 !important;
        color: #fff;
        margin: 0 6px !important;
    }
}
