.circle-progrss-wrapper {
    width: 112px;
    height: 112px;
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
}

.profile-wrapper .ant-space {
    gap: 0 !important
}

.m-web-show {
    display: none;
}

.attendance-elem .d-space-wrapper .ant-space-item:last-child {
    margin-left: auto;
}


@media screen and (min-width: 993px) {
    .das-section {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 992px) {
    .d-padding {
        padding: 0 !important
    }

    .das-section {
        margin-top: -80px !important;
    }
}


@media screen and (max-width: 768px) {
    .attendance-elem {
        width: 100% !important;
        margin: 2px 0px 4px !important;
    }

    .d-web-show {
        display: none;
    }

    .m-web-show {
        display: block;
    }

    .p-mweb-card {
        padding: 0 10px 10px !important
    }

    .generic-table .ant-table-thead th,
    .generic-table .ant-table-tbody td {
        padding: 10px 5px !important;
    }

    .generic-table .table-th-icon {
        display: none !important
    }
}