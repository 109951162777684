.enquiry-details-tab-item .ant-tabs-tab.ant-tabs-tab-active {
    background: #002A52;
}
.enquiry-details-tab-item .ant-tabs-tab .ant-tabs-tab-btn {
   font-size: 14px;
   color: #000000;
   padding: 4px 12px !important;
}
.enquiry-details-tab-item .ant-tabs-tab {
    background: #E0EEF7;
    margin-right: 10px !important;
    border-radius: 6px;
}
.card-custom-row .ant-card-meta-title {
    font-size: 14px;
    margin-bottom: 0 !important;
    color: #343433
} 
.card-custom-row .ant-card-meta-description {
    color: #343433
}
.enquiry-personal-details .ant-card-meta {
    display: flex;
    align-items: center;
}
.form-custom-select .ant-select-selector {
    border: 0 !important;
    padding: 0 8px !important;
    height: 30px !important;
}
.custom-meta-radio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #1677ff !important;
}
.custom-meta-radio .ant-radio-button-wrapper {
    border-color: #92B5D7 !important;
    background-color: #92B5D7 !important;
    margin-right: 10px;
    border-radius: 4px;
    padding: 0px 25px;
}
.custom-meta-radio .ant-radio-button-wrapper::before {
    display: none;
}
.phone-style {
    margin-left: auto;
    border: 1px solid #6D6C68;
    border-radius: 4px;
    padding: 4px 10px;
}
.m-web-slider {
    display: none !important;
}
.m-web-slider .slick-dots {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    width: 106px;
    margin: auto;
    padding: 2px;
    border-radius: 4px;
    position: fixed;
}
.m-web-slider .slick-dots li { 
    width: 24px;
    height: 36px;
    margin-inline: 1px;
    border-radius: 2px;
    background: #E3E3E3;
}
.m-web-slider .slick-dots li button {
    height: 100% !important;
    width: 100% !important;
    background: transparent !important;
    border-radius: 2px;
}
.m-web-slider .slick-dots li.slick-active button{ 
    border: 1px solid #1677FF;
}
.slider-arrow {
    position: fixed;
    width: 50px;
    height: 50px;
    top: 50%;
    margin: auto;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
}
.slider-arrow.left {
    left: 10px
}
.slider-arrow.right {
    right: 10px
}
.reason-wrapper {
    padding-left: 50px;
}

@media screen and (max-width: 767px) {
    .m-web-slider {
        display: block !important;
    }
    .d-web-grid {
        display: none;
    }
    .custom-tab-style .ant-tabs-extra-content {
        display: none;
    }
    .enquiry-details-tab-item .ant-tabs-tab .ant-tabs-tab-btn {
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .enquiry-details-tab-item .ant-tabs-tab {
        padding: 5px !important
    }
    .reason-wrapper {
        padding-left: 0;
    }
    .add-new-details input, .add-new-details .ant-picker, .add-new-details .ant-select {
        width: 100% !important;
    }
    .follow-up-details .ant-col {
        padding: 0 !important;
    }
}