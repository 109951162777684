body {
    background: #f5f5f5;
}

.filter-drawer.ant-drawer-content {
    background: transparent !important;
}
.filter-drawer .ant-drawer-header-title {
    display: none;
}
.filter-drawer .ant-drawer-header {
    padding: 0 !important;
    border: none !important;
}
.filter-drawer-wrapper .ant-drawer-content-wrapper{
    top: 30px !important;
    box-shadow: none;
}
.filter-drawer-wrapper .ant-drawer-body {
    background: #ffffff;
    box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}
.filter-drawer .ant-drawer-extra {
    width: 100%;
    display: flex;
    justify-content: end;
}
.drawer-fb-wrapper {
    margin-right: 10px;
    padding: 12px 12px;
    border-radius: 4px 4px 0 0;
    background: #ffffff;
    z-index: 9;
}
.task-main-table .ant-table-thead th{
    background: #E0EEF7 !important;
}
.header-row .ant-col-4 {
    max-width: 14% !important;
}
.custom-tag .anticon-close svg {
    color: #002A52;
}
.custom-select .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
}
.custom-select .ant-select-selector .ant-select-selection-item{
    line-height: 24px !important;
}
.task-details-select .ant-select-selector {
    height: 26px !important;
    padding: 0 9px;
}
.read-only .ck-content p {
    margin: 0 !important;
}
.read-only .ck-editor__top {
    display: none;
}


.task-detail-card .ant-tabs-tab.ant-tabs-tab-active{
    background: #6D6C68;
}
.custom-avatar {
    border: 1px solid rgba(52, 52, 51, 0.25);
    background: rgba(52, 52, 51, 0.25);
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 8px 6px;
    margin-right: 12px;
}

.add-comment-editor-form .ant-form-item {
    margin-bottom: 10px !important;
}
.add-comment-editor-form .ant-form-item-explain {
    padding: 10px 10px;
    background: #ffffff !important;
}
.task-main-table .ant-table-column-sorters {
    justify-content: unset !important;
}
.task-main-table .ant-table-column-title {
    flex: 0 !important;
    margin-right: 6px;
}
.task-main-table.ant-table-wrapper .ant-table-column-sorter-up, .task-main-table.ant-table-wrapper .ant-table-column-sorter-down {
    font-size: 13px !important;
}
.custom-drawer-btn {
    display: none;
}
.custom-drawer--mask {
    display: none;
}
.t-detail-wrapper {
    margin-right: 10px;
}



@media screen and (max-width: 992px) {

    .custom-drawer-btn {
        display: block;
        z-index: 99;
    }
    .custom-drawer--mask {
        position: fixed;
        background: rgba(0,0,0,0.3);
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 00;
    }
    .custom-drawer--mask.active {
        display: block;
    }
    .custom-drawer-card {
        position: fixed;
        z-index: 99;
        right: 0;
        top: 110px;
        transform: translateX(100%)
    }
    .custom-drawer-card.open {
        transform: translateX(0)
    }
    .task-table table {
        display: flex;
    }

    .task-table tr {
        display: flex;
        flex-direction: column;
    }
    .task-table thead, .task-table tbody {
        display: flex;
    }
    .ant-table-cell .task-table-column{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .task-table .ant-table-tbody {
        width: 100%;
        overflow: auto;
    }
    .task-table .ant-table-thead .ant-table-cell {
        width: 80px;
        justify-content: flex-start;
        padding: 6px 12px !important;
    }
    .task-table .ant-table-cell {
        height: 75px;
        padding: 8px 12px !important;
        display: flex;
        align-items: center;
        background: #ffffff;
    }
    .task-table .ant-table {
        background: transparent;
    }
    .task-table .ant-table-filter-column  {
        align-items: baseline;
    }
    .task-table .ant-table-column-sorters {
        flex-direction: column;
    }
    .task-table .ant-table-column-title {
        margin-bottom: 6px;
    }
    .add-task-item {
        flex-direction: column;
    }
    .w-50 {
        width: 50px !important;
    }

    .task-main-table .ant-table-column-title {
        margin: 0 !important;
    }
    .ant-table-cell .task-table-column .mr-2 {
        margin: 0 !important;
    }
    .filter-drawer-wrapper .ant-drawer-extra {
        display: none !important;
    }
    .filter-drawer-wrapper .ant-drawer-body {
        overflow: hidden !important;
        border-radius: 10px 0 0 10px;
    }
    .filter-drawer.ant-drawer-content {
        height: 600px !important;
    }
    .filter-form-items {
        height: 500px;
        overflow-y: auto !important;
    }
    .filter-btn.open-filter-drawer {
        z-index: 9999;
        background: #E0EEF7 !important;
        color: #000000 !important;
    }
    .filter-drawer-wrapper .ant-drawer-content-wrapper {
        top: 90px !important;
    }
    .t-detail-wrapper {
        margin-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .main-page-row {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .main-page-row .ant-col {
        max-width: 100%;
        margin-bottom: 10px;
    }
    .filter-drawer-wrapper .ant-drawer-content-wrapper {
        width: 300px !important;
        top: 110px !important;
    }
    .m-web-modal .ant-modal-body {
        height: 550px !important; 
    }
    .add-task-item .ant-select {
        width: 100% !important;
        margin-right: 0 !important;
    }
    .task-calender {
        display: none !important;
    }
    .main-task-headeing {
        align-items: center !important;
    }
    .filter-drawer.ant-drawer-content {
        height: 550px !important;
    }
    .filter-form-items {
        height: 470px;
        overflow-y: auto !important;
    }
    
    .filter-drawer-wrapper .ant-divider-horizontal {
        margin: 18px 0;
    }
    .apply-btn-styles {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    
    
 }
