.report-section {
    margin-top: -10px;
    padding: 0 10px;
}
.report-button-wrapper {
    margin-bottom: 20px;
}
.report-dropdown-wrapper {
    display: flex;
}
.report-right-wrapper {
    margin-left: auto;
    text-align: right;
}
.report-dropdown {
    margin-right: 20px;
    width: auto;
}
.report-dropdown .ant-btn, .report-dropdown.ant-btn {
    background: #E0EEF7 !important;
    color: #000000;
    font-weight: 500;
    border: 0;
}
.report-dropdown .ant-btn.ant-btn-icon-only {
    border-left: 1px solid #92B5D7;
}
.report-dropdown.active-report-dropdown .ant-btn.ant-btn-icon-only {
    border-left: 1px solid #D9D9D9;
}
.report-dropdown.active-report-dropdown .ant-btn, .report-dropdown.active-report-dropdown.ant-btn {
    background: #002A52 !important;
    color: #ffffff;
}

.phone-call-table thead .ant-table-cell {
    background: #E0EEF7 !important;
    padding: 10px !important;
}
.phone-call-table tbody .ant-table-cell {
    padding: 6px 10px !important;
}
.phone-call-table thead .ant-table-cell::before {
    display: none !important;
}
.phone-call-table table > thead > tr:first-child >*:first-child {
    border-start-start-radius: 5px;
    border-bottom-left-radius: 5px;
}
.phone-call-table table > thead >tr:first-child >*:last-child {
    border-start-end-radius: 5px;
    border-bottom-right-radius: 5px;
}
.flex-flow-css {
    flex-flow: row !important;
}
.emp-report-table {
    margin-left: 10px !important;
}
.report-header {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    padding: 15px 0 15px 215px;
    z-index: 99;
    background: whitesmoke;
}

@media only screen and (max-width: 992px) { 
    .mweb-performance-meter {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
    }
    .flex-flow-css {
        flex-flow: row wrap !important;
    }
    .emp-report-table {
        margin-top: 20px !important;
    }
    .margin-l-0 {
        margin-left: 0 !important;
    }
    .report-right-wrapper {
        margin-top: 0;
        text-align: right;
    }
    .report-dropdown {
        margin-right: 8px;
    }
    .report-section {
        margin-top: 10px;
    }
    .report-header {
        padding-left: 60px;
        padding-top: 30px;
    }
    
    
}  

@media only screen and (max-width: 767px) { 

    .report-section {
        margin-top: -50px;
    }
    .report-right-wrapper {
        margin-top: 20px;
        text-align: left;
    }
    .report-dropdown-wrapper {
        flex-wrap: wrap;
    }
    .report-dropdown:last-child {
        margin-top: 10px;
    }


}   
