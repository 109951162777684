.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-common-element-ui {
  background: #001529;
  height: 100vh;
}

.bordered-common-ui-card {
  background: #001529;
  font-size: 22px;
  width: 800px;
  margin: 32px;
}
.card-colomn {
  color: white;
  position: relative;
}

.image-dev {
  width: 480px;
  background: transparent;
  margin: 16px;
}
.responsive-image {
  width: 80%;
  height: 100%;
  object-fit: cover;
}

.card-floating-header {
  position: absolute;
  top: -54px !important;
  padding: 5px;
  font-size: 70px !important;
  background: #001529;
}
.para {
  font-size: x-large;
}
.para-padding {
  padding: 21px;
}
.font-color-white {
  color: #f0f0f0 !important;
}

@media screen and (max-width: 575px) {
  .bordered-common-ui-card {
    width: 48vw !important;
  }
  .alert-card {
    width: fit-content;
    align-items: baseline !important;
    justify-content: center;
  }
  .responsive-image {
    width: 50% !important;
  }
  .card-floating-header {
    top: -48px !important;
    font-size: 48px !important;
  }
  .para {
    font-size: large !important;
  }
}

@media screen and (max-width: 768px) {
  .bordered-common-ui-card {
    width: 65vw !important;
    font-size: xx-large;
  }
  .alert-card {
    width: fit-content;
    align-items: baseline !important;
    justify-content: center;
    margin-left: 0;
  }

  .responsive-image {
    width: 60% !important;
    display: flex;
    justify-content: center;
  }
  .card-floating-header {
    top: -48px !important;
    font-size: 48px !important;
  }
  .para {
    font-size: medium;
  }
}

@media screen and (max-width: 991px) {
  .row-common-element-ui {
    align-content: baseline;
  }
  .bordered-common-ui-card {
    width: 85vw !important;
  }
  .order-2 {
    order: 2 !important;
  }
  .alert-card {
    width: fit-content;
    align-items: baseline !important;
    justify-content: center;
  }

  .responsive-image {
    width: 60% !important;
    display: flex;
    justify-content: center;
  }
  .card-floating-header {
    top: -48px;
    font-size: 64px;
  }
  .para {
    font-size: xx-large;
  }
}
@media screen and (max-width: 1200px) {
  .card-floating-header {
    top: -48px !important;
    font-size: 53px !important;
  }
}
