.ck-editor__editable {
    min-height: 80px;
}

.add-task-form .editor-custom-view .ck-content {
    height: 100px;
    overflow: auto;
}
.add-task-form .editor-custom-view .ck-content p {
    margin: 0 !important;
}
.add-task-form .ant-select-selector {
    background: #E0EEF7 !important;
}