.emp-card-wrapper {
    flex-direction: row !important;
    align-items: center !important;
}
.emp-body-wrapper {
    align-items: center;
}


@media screen and (max-width: 992px) {
    .emp-card-wrapper {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .emp-body-wrapper {
        align-items: flex-start;
    }
    
}
  
@media screen and (max-width: 767px) {
   
}