.lead-details-tab-item  .ant-tabs-tab.ant-tabs-tab-active {
    background: #002A52;
}
.lead-details-tab-item  .ant-tabs-tab .ant-tabs-tab-btn {
   font-size: 14px;
   color: #000000;
   padding: 4px 12px !important;
}
.lead-details-tab-item  .ant-tabs-tab {
    background: #E0EEF7;
    margin-right: 10px !important;
    border-radius: 6px;
}
.card-custom-row .ant-card-meta-title {
    font-size: 14px;
    margin-bottom: 0 !important;
    color: #343433
} 
.card-custom-row .ant-card-meta-description {
    color: #343433
}
.lead-personal-details .ant-card-meta {
    display: flex;
    align-items: center;
}
.form-custom-select .ant-select-selector {
    border: 0 !important;
    padding: 0 8px !important;
    height: 30px !important;
}
.custom-meta-radio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #1677ff !important;
}
.custom-meta-radio .ant-radio-button-wrapper {
    border-color: #92B5D7 !important;
    background-color: #92B5D7 !important;
    margin-right: 10px;
    border-radius: 4px;
    padding: 0px 25px;
}
.custom-meta-radio .ant-radio-button-wrapper::before {
    display: none;
}