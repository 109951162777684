.background-container {
  background-image: url("../../../public/images/log-in-bg.jpeg");
  background-size: cover;
  background-position: center;
}
.gradient {
  display: flex;
  height: 100vh;
  background: linear-gradient(
    270.31deg,
    #001529 20%,
    rgba(0, 21, 41, 0.929875) 50.62%,
    rgba(1, 22, 43, 0.714652) 63.17%,
    rgba(2, 23, 44, 0.543614) 74.72%,
    rgba(4, 26, 48, 0) 99.71%
  );
}
.flex-align-item-center {
  display: flex;
  align-items: center;
}
.font-color-white {
  color: white !important;
}
.main-heading {
  font-size: 90px !important;
  height: fit-content;
}

.reg-card {
  width: 400px;
  max-height: 90vh;
  overflow: auto;
  background-image: linear-gradient(
    254.51deg,
    rgb(185, 182, 182),
    rgba(85, 85, 85, 1)
  );
  opacity: 0.8;
}

.primary-heading-column {
  justify-content: end;
}
.login-card-column {
  justify-content: center;
}

@media screen and (max-width: 575px) {
  .reg-card {
    max-width: 320px;
    max-height: 40px;
  }
}

@media screen and (max-width: 768px) {
  .gradient {
    background: linear-gradient(
      331.94deg,
      #001529 40.09%,
      rgba(0, 21, 41, 0.929875) 48.31%,
      rgba(1, 22, 43, 0.714652) 61.04%,
      rgba(2, 23, 44, 0.543614) 72.76%,
      rgba(4, 26, 48, 0) 98.1%
    );
    align-content: baseline;
  }
  .reg-card {
    max-height: 72vh;
  }
}

@media screen and (max-width: 992px) {
  .primary-heading-column {
    justify-content: center;
  }
  .login-card-column {
    align-items: baseline;
  }
  .main-heading {
    height: fit-content;
    margin-top: 50px;
    font-size: 48px !important;
  }
}
