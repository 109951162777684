/* @import 'https://code.highcharts.com/css/highcharts.css'; */
#container {
  min-width: 300px;
  margin: 1em auto;
}

#container h4 {
  text-transform: none;
  font-size: 12px;
  font-weight: normal;
}

#container p {
  font-size: 10px;
  line-height: 16px;
}

h4 {
  font-size: 9.5px !important;
}

@media (min-width: 576px) {
  h4 {
    font-size: 10px !important;
  }
}

@media (min-width: 768px) {
  h4 {
    font-size: 10px !important;
  }
}

@media (min-width: 992px) {
  h4 {
    font-size: 10px !important;
  }
}

@media (min-width: 1200px) {
  h4 {
    font-size: 10px !important;
  }
}